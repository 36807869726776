import React from 'react'
import styled from 'styled-components'

const IframeWrapper = styled.div`
    margin: 0 auto;

    @media (min-width: 992px) {
        /* margin: 2.5% auto;    */
    }

    iframe {
        border: 0;
    }
`

const TrailerIframe = (props) => {
    return(
        <IframeWrapper {...props}>
            <iframe
                allowFullScreen
                allow="autoplay *; fullscreen *; encrypted-media *"
                src={props.src}
                title="Lion King Education"
                className="embed-responsive-item"
            />
        </IframeWrapper>
    )
}

export default TrailerIframe