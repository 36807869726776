import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import TrailerIframe from 'components/shared/TrailerIframe'
import ButtonClose from 'components/shared/ButtonClose'

const VModal = styled(Modal)`
    max-width: 1400px;
    padding: 0;
    border: 0;

    .modal-content {
        border: 0;
        position: relative;
    }

    .modal-body {
        padding: 0;
        border: 0;
    }
`

class KalturaModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                <ModalBody>
                    <TrailerIframe src={this.props.src} className="embed-responsive embed-responsive-16by9"/>
                </ModalBody>
            </VModal>
        );
    }
}

export default KalturaModal;